import { checkTypeExist, checkTypeExistArray } from "@common/features/Post";
import InstagramOfficialReelsSection from "@common/features/Post/sections/InstagramOfficialReelsSection";
import InstagramReelsSection from "@common/features/Post/sections/InstagramReelsSection";
import { useFeatureSupports } from "@common/features/User";
import { ElementsGroup } from "@app/elements/container";
import AparatSection from "../sections/AparatSection";
import AutoDeleteSection from "../sections/AutoDeleteSection";
import BaleSection from "../sections/BaleSection";
import CollabSection from "../sections/CollabSection";
import EitaaSection from "../sections/EitaaSection";
import FirstCommentSection from "../sections/FirstCommentSection";
import GapSection from "../sections/GapSection";
import HashtagSection from "../sections/HashtagSection";
import LinkedinSection from "../sections/LinkedinSection";
import LocationSection from "../sections/LocationSection";
import PinterestSection from "../sections/PinterestSection";
import TelegramSection from "../sections/TelegramSection";
import TikTokSection from "../sections/TikTokSection";
import TwitterSection from "../sections/TwitterSection";
import YouTubeSection from "../sections/YouTubeSection";
import { usePostStore } from "../store/post";

export function PostSections() {
  const [{ selectedAccounts, selectedFiles }] = usePostStore(
    ({ selectedAccounts, selectedFiles }) => ({
      selectedAccounts,
      selectedFiles,
    }),
    true,
  );

  const featureSupports = useFeatureSupports();

  const sectionsVisible = {
    hashtag: checkTypeExistArray(selectedAccounts, featureSupports.hashtag),
    location: checkTypeExistArray(selectedAccounts, featureSupports.location),
    telegram: checkTypeExist(selectedAccounts, "Telegram"),
    eitaa:
      selectedFiles.length <= 1 && checkTypeExist(selectedAccounts, "Eitaa"),
    gap: selectedFiles.length <= 1 && checkTypeExist(selectedAccounts, "Gap"),
    bale: selectedFiles.length <= 1 && checkTypeExist(selectedAccounts, "Bale"),
    tiktok: checkTypeExist(selectedAccounts, "TikTok"),
    aparat: checkTypeExist(selectedAccounts, "Aparat"),
    youtube: checkTypeExist(selectedAccounts, "YouTube"),
    pinterest: checkTypeExist(selectedAccounts, "Pinterest"),
    twitter: checkTypeExist(selectedAccounts, "Twitter"),
    linkedin:
      selectedFiles.length === 1 &&
      selectedFiles[0].fileType === "video" &&
      checkTypeExist(selectedAccounts, "Linkedin"),
    instagramOfficial: selectedAccounts.some(
      (account) => account.type === "InstagramOfficial",
    ),
    instagram: selectedAccounts.some((account) => account.type === "Instagram"),
    autoDelete: checkTypeExistArray(
      selectedAccounts,
      featureSupports.auto_delete,
    ),
  };

  return (
    <ElementsGroup>
      {sectionsVisible.instagramOfficial && (
        <InstagramOfficialReelsSection
          disabled={
            selectedFiles.length !== 1 || selectedFiles[0].fileType !== "video"
          }
        />
      )}
      {sectionsVisible.instagram && (
        <InstagramReelsSection
          disabled={
            selectedFiles.length !== 1 || selectedFiles[0].fileType !== "video"
          }
        />
      )}
      {(sectionsVisible.instagramOfficial || sectionsVisible.instagram) && (
        <CollabSection />
      )}
      {sectionsVisible.telegram && (
        <TelegramSection showButton={selectedFiles.length < 2} />
      )}
      {sectionsVisible.aparat && <AparatSection />}
      {sectionsVisible.youtube && <YouTubeSection />}
      {sectionsVisible.pinterest && <PinterestSection />}
      {sectionsVisible.linkedin && <LinkedinSection />}
      {sectionsVisible.bale && <BaleSection />}
      {sectionsVisible.eitaa && <EitaaSection />}
      {sectionsVisible.gap && <GapSection />}
      {sectionsVisible.tiktok && <TikTokSection />}
      <HashtagSection disabled={!sectionsVisible.hashtag} />
      {sectionsVisible.instagramOfficial && (
        <LocationSection disabled={!sectionsVisible.location} />
      )}
      <FirstCommentSection
        disabled={
          !sectionsVisible.instagramOfficial || !sectionsVisible.instagram
        }
      />
      <TwitterSection disabled={!sectionsVisible.twitter} />
      <AutoDeleteSection disabled={!sectionsVisible.autoDelete} />
    </ElementsGroup>
  );
}
