import { DataResponse } from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { MenuItemsType } from "./layout/Menu";
import AutomationSidebar from "./pages/automation/AutomationSidebar";

export const generateMenu = (data: DataResponse) => {
  const menu: MenuItemsType[] = [
    {
      url: "/dashboard",
      label: __("Dashboard"),
      icon: data.masterUser!.user_type
        ? regular("gauge")
        : regular("gauge-circle-bolt"),
    },
    {
      url: "/post",
      label: __("Publish"),
      icon: regular("pen-to-square"),
      teamPermission: "post_schedule",
    },
    {
      icon: regular("file-lines"),
      teamPermission: "post_schedule",
      label: __("Contents"),
      childs: [
        {
          label: __("Contents"),
          url: "/posts",
          icon: regular("memo"),
        },
        {
          label: __("Calendar"),
          url: "/schedule-calendar",
          icon: regular("calendar-day"),
        },
        {
          label: __("Drafts"),
          url: "/draft",
          icon: regular("file-pen"),
        },
      ],
    },
    {
      url: "/comment",
      label: __("Comments"),
      icon: regular("comment-lines"),
      teamPermission: "comment_view",
      unread: () => !!data.masterUser!.comment_unread,
    },
    {
      url: "/inbox",
      label: __("Messages"),
      icon: regular("envelope"),
      teamPermission: "inbox_view",
      unread: () => !!data.masterUser!.inbox_unread,
    },
    {
      url: "/insights",
      label: __("Insights"),
      icon: regular("chart-simple"),
      teamPermission: "insights_view",
    },
    {
      label: __("Automation"),
      icon: regular("message-bot"),
      url: "/automation",
      unread: () => true,
      showSelectOnSmall: true,
      Component: AutomationSidebar,
      childs: [
        {
          label: __("Auto response"),
          url: "/response",
          icon: regular("reply"),
        },
        {
          label: __("Form builder"),
          url: "/form",
          icon: regular("list-check"),
        },
        {
          label: __("Persistent menu"),
          url: "/menu",
          icon: regular("bars"),
        },
        {
          label: __("Frequently asked questions"),
          url: "/question",
          icon: regular("message-question"),
        },
        {
          label: __("Products & services"),
          url: "/product",
          icon: regular("boxes-stacked"),
        },
        {
          label: __("Leeds"),
          url: "/leed",
          icon: regular("address-book"),
        },
        {
          label: __("Sent messages history"),
          url: "/log",
          icon: regular("memo-circle-info"),
        },
      ],
    },
    {
      icon: regular("gear"),
      label: __("Settings"),
      teamPermission: false,
      childs: [
        {
          url: "/accounts",
          label: __("Connect accounts"),
          icon: regular("plug"),
          teamPermission: false,
        },
        {
          url: "/team",
          label: __("Team Members"),
          icon: regular("user-group"),
          teamPermission: false,
        },
        {
          url: "/integrations",
          label: __("Integrations"),
          icon: regular("grid-2-plus"),
          teamPermission: false,
        },
        {
          url: "/ticket",
          label: __("Support ticket"),
          icon: regular("life-ring"),
          teamPermission: false,
        },
        {
          url: "/user/profile",
          label: __("Profile settings"),
          icon: regular("user-gear"),
        },
        {
          url: "/user/security",
          label: __("Security settings"),
          icon: regular("lock"),
        },
        {
          url: "/user/notifications",
          label: __("Notification settings"),
          icon: regular("bell"),
        },
        {
          url: "/renew",
          label: __("Subscription"),
          icon: regular("bolt"),
        },
        {
          url: "/order",
          label: __("Billing & Plan"),
          icon: regular("credit-card"),
        },
        // {
        //   url: "/affiliate",
        //   label: __(window.sharedData.affiliate_sal)e
        //     ? "Affiliate"
        //     : "Invite friends",
        //   icon: regular("megaphone"),
        // },
        import.meta.env.VITE_HELP_URL !== "" && {
          url: import.meta.env.VITE_HELP_URL,
          label: __("Help"),
          icon: regular("circle-question"),
          external: true,
        },
      ].filter(Boolean) as MenuItemsType["childs"],
    },
  ];

  return menu;
};
