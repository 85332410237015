import { useState } from "react";
import { useExtraPostState } from "@common/features/Post";
import { regular } from "@common/helpers/fontawesome";
import { parseIntUndefined } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import { SectionToggleProps } from "@common/types";
import { ElementsGroup, SectionToggle } from "@app/elements/container";
import { CheckBox, TextInput } from "@app/elements/form";
import { Tooltip } from "@app/elements/helpers";
import { SocialIcon } from "@app/elements/social";
import { Text } from "@app/elements/text";
import { DropBox } from "@app/features/filemanager";

export default function InstagramReelsSection(
  props: Partial<SectionToggleProps>,
) {
  const [coverToggle, setCoverToggle] = useState(false);
  const [data, dispatch] = useExtraPostState("Instagram");

  return (
    <SectionToggle
      {...props}
      title={__("Instagram reels")}
      hint={__("Instagram reels only available for Instagram and video")}
      icon={<SocialIcon social="Instagram" />}
      defaultOpen={data.reels_sharetofeed !== undefined}
    >
      <ElementsGroup>
        <CheckBox
          checked={data.reels_sharetofeed === 1}
          onInputChange={(checked) =>
            dispatch("reels_sharetofeed", checked ? 1 : 0)
          }
          label={
            <Tooltip
              tooltip={__(
                "By selecting this video will send in feed otherwise it only available in reels tab",
              )}
            >
              <Text icon={regular("circle-question")} iconPosition="end" block>
                {__("Share to feed")}
              </Text>
            </Tooltip>
          }
        />
        <CheckBox
          id="video_cover"
          checked={coverToggle}
          onInputChange={() => setCoverToggle(!coverToggle)}
          label={
            <>
              <Tooltip
                tooltip={__(
                  "It is recommended that the cover image should be 9 x 16 in jpeg format and less than 8 MB in size.",
                )}
              >
                <Text
                  icon={regular("circle-question")}
                  iconPosition="end"
                  block
                >
                  {__("Cover image")}
                </Text>
              </Tooltip>
            </>
          }
        />
        {coverToggle && (
          <DropBox
            selectedFileId={parseIntUndefined(data.video_cover)}
            onChange={(fileId) => dispatch("video_cover", fileId + "")}
            allowType={["image"]}
            sizeCaption={{ maxSize: 8 }}
          />
        )}
        <TextInput
          label={__("Change reels audio name")}
          placeholder={__("Enter audio name")}
          value={data.reels_audioname || ""}
          onInputChange={(audioname) => dispatch("reels_audioname", audioname)}
          name="audioname"
        />
      </ElementsGroup>
    </SectionToggle>
  );
}
