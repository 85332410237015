import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FetchAccountPost } from "@common/features/Social";
import { api } from "@common/helpers/api";
import {
  deleteDataOnArray,
  insertDataOnArray,
  updateDataOnArray,
} from "@common/helpers/queries";
import { CursorPaginate } from "@common/interfaces/Api";
import { Account, AccountCount, AccountPage } from "../interfaces";

export const accountsKeys = createQueryKeys("accounts", {
  list: null,
  team: null,
  posts: (accountId?: number, after?: string) => [{ accountId, after }],
  account: (accountId?: number) => [{ accountId }],
  pages: (pageId?: number) => [{ pageId }],
  count: null,
});

export const useFetchAccounts = <TData = Account[]>(
  select?: (data: Account[]) => TData,
) =>
  useQuery({
    ...accountsKeys.list,
    queryFn: async () => {
      const response = await api.get<Account[]>("account", {
        params: { with: "socialUser" },
      });
      return response.data;
    },
    select,
  });

export const useFetchTeamAccounts = <TData = Account[]>(
  select?: (data: Account[]) => TData,
  enabled?: boolean,
) =>
  useQuery({
    ...accountsKeys.team,
    queryFn: async () => {
      const response = await api.get<Account[]>("account/team", {
        params: { with: "meta" },
      });
      return response.data;
    },
    select,
    enabled,
  });

export const useFetchAccount = (accountId?: number) =>
  useQuery({
    ...accountsKeys.account(accountId),
    queryFn: async () => {
      const response = await api.get<Account>(`account/${accountId}`, {
        params: { with: "socialUser,meta" },
      });
      return response.data;
    },
    enabled: accountId !== undefined,
  });

export const useFetchAccountPages = (accountId?: number) =>
  useQuery({
    ...accountsKeys.pages(accountId),
    queryFn: async () => {
      const response = await api.get<AccountPage[]>(
        `account/${accountId}/pages`,
      );
      return response.data;
    },
    enabled: accountId !== undefined,
  });

export const useFetchAccountCount = (enabled = true) =>
  useQuery({
    ...accountsKeys.count,
    queryFn: async () => {
      const response = await api.get<AccountCount>("account/count");
      return response.data;
    },
    enabled,
  });

export const useCreateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) =>
      api
        .post("account", { ...data, with: "meta" })
        .then((response) => response.data),
    onSuccess: (response) => {
      queryClient.setQueryData(
        accountsKeys.account(response.id).queryKey,
        response,
      );

      queryClient.setQueryData<Account[]>(
        accountsKeys.list.queryKey,
        (previous) => insertDataOnArray(previous, response),
      );

      queryClient.invalidateQueries({
        queryKey: accountsKeys.list.queryKey,
        refetchType: "none",
      });

      queryClient.invalidateQueries({ queryKey: accountsKeys._def });
    },
  });
};

export const useUpdateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, ...data }: any) =>
      api
        .put(`account/${id}`, { ...data, with: "meta" })
        .then((response) => response.data),
    onSuccess: (response) => {
      queryClient.setQueryData(
        accountsKeys.account(response.id).queryKey,
        response,
      );

      queryClient.setQueryData<Account[]>(
        accountsKeys.list.queryKey,
        (previous) => updateDataOnArray(previous, response),
      );

      queryClient.invalidateQueries({ queryKey: accountsKeys._def });
    },
  });
};

export const useDeleteAccount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => api.delete(`account/${id}`),
    onSuccess: (_data, variables) => {
      queryClient.removeQueries(accountsKeys.account(variables));

      queryClient.setQueryData<Account[]>(accountsKeys._def, (previous) =>
        deleteDataOnArray(previous, variables),
      );

      queryClient.invalidateQueries({ queryKey: accountsKeys._def });
    },
  });
};

export const useUpdateAccountPage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      pageId,
      accountId,
    }: {
      accountId: number;
      pageId: string;
    }) => api.put(`account/${accountId}/pages`, { page: pageId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: accountsKeys._def });
    },
  });
};

export const useUpdateAutomationStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      status,
      accountId,
    }: {
      accountId: number;
      status: boolean;
    }) => api.put(`account/${accountId}/automation/status`, { status }),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: accountsKeys._def });
    },
  });
};

export type fetchPostList = CursorPaginate<FetchAccountPost>;
export const useFetchAccountPosts = (accountId: number, after?: string) =>
  useQuery({
    ...accountsKeys.posts(accountId, after),
    queryFn: async () => {
      return (
        await api.get<fetchPostList>(`account/${accountId}/fetch-posts`, {
          params: { after: after },
        })
      ).data;
    },
    enabled: accountId !== undefined,
  });
