import { SocialNetwrokType } from "../Social";

export const YoutubePrivacyList = {
  private: "Private",
  public: "Public",
  unlisted: "Unlisted",
};

export const AparatCategoryList = {
  22: "بازی",
  1: "شخصی",
  2: "طنز",
  3: "آموزشی",
  4: "تفریحی",
  5: "فیلم",
  6: "مذهبی",
  7: "موسیقی",
  8: "خبری",
  9: "سیاسی",
  10: "علم و تکنولوژی",
  11: "ورزشی",
  12: "حوادث",
  13: "گردشگری",
  14: "حیوانات",
  15: "متفرقه",
  16: "تبلیغات",
  17: "هنری",
  18: "کارتون",
  20: "بانوان",
  21: "سلامت",
  23: "آشپزی",
};

export const AccountDescription = {
  Aparat: "Aparat channel",
  Bale: "Bale channel",
  Eitaa: "Eitaa channel",
  Gap: "Gap channel",
  Soroush: "Soroush channel",
  Whatsapp: "Whatsapp bussiness account",
  Instagram: "Instagram profile",
  InstagramOfficial: "Instagram profile (via facebook page)",
  Facebook: "Facebook page",
  TikTok: "TickTok Profile",
  Telegram: "Telegram",
  Pinterest: "Pinterest board",
  Twitter: "Twitter profile",
  YouTube: "YouTube channel",
  Linkedin: "Linkedin company page",
  Tumblr: "Tumblr blog",
} as const satisfies { [key in SocialNetwrokType]: string };

export const TikTokPrivacyList = {
  PUBLIC_TO_EVERYONE: "Public",
  MUTUAL_FOLLOW_FRIENDS: "Mutual follow friend",
  SELF_ONLY: "Self only",
} as const;
